<script setup>
import searchConfig from '@/config/search';
import { WdsSearchFormInline, WdsSearchFormStacked, WdsSearchFormWizard } from '@wds/components';

defineProps({
  hideOnSmallScreen: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// ### STORE/PINIA
const searchStore = useSearchStore();
const locationStore = useLocationStore();
const testingStore = useTestingStore();

// ### COMPOSABLES
const nuxtApp = useNuxtApp();
const dateTime = useDateTime();
const searchData = useSearch();

// ### COMPUTED
const searchFormInlineParams = computed(() => ({
  size: 'large',
  ...searchData.getSearchFormCommonParams.value,
}));

const searchFormWizardParams = computed(() => ({
  activeStep: searchData.wizardActiveStep.value,
  openWizard: searchData.wizardIsOpen.value,
  focusDestinationOnOpen: true,
  ...searchData.getSearchFormCommonParams.value,
}));

const searchFormStackedParams = computed(() => ({
  size: 'small',
  ...searchData.getSearchFormCommonParams.value,
}));

const getDestinationObject = computed(() => {
  const locationType = locationStore.getLocationType;
  const locationInfo = locationStore.getLocationFullInfo;
  switch (locationType) {
    case 'city':
      return {
        id: locationInfo.city?.id || 0,
        type: 'city',
        label: locationInfo.city?.name,
        labelSmall: locationInfo.country?.name,
        value: locationInfo.city?.id || 0,
      };
    case 'district':
      return {
        id: locationInfo.district?.id || 0,
        cityId: locationInfo.city?.id || 0,
        type: 'citydistricts',
        label: locationInfo.district?.name,
        labelSmall: [locationInfo.city?.name, locationInfo.country?.name].join(', '),
        value: locationInfo.district?.id || 0,
      };
    case 'property':
      return {
        id: locationInfo?.id || 0,
        type: 'property',
        label: locationInfo.property?.name,
        value: locationInfo.property?.id || 0,
      };
    default:
      return {};
  }
});

// ### A/B TESTING FEATURE TOGGLES
const experimentDefaultDates = testingStore.hasEnabledFeature('web_default_checkin_date_experiment');

// ### CREATED
if (experimentDefaultDates) {
  const dayOfWeek = dateTime.getDayOfWeek(new Date());
  const defaultAddDays = searchConfig.defaultIncrementDays[dayOfWeek];

  searchStore.setSearchCheckIn(dateTime.addDaysToDate(dateTime.today, defaultAddDays));
  searchStore.setSearchCheckOut(dateTime.addDaysToDate(dateTime.tomorrow, searchConfig.defaultNights + defaultAddDays));
} else {
  searchStore.setSearchCheckIn(dateTime.tomorrow);
  searchStore.setSearchCheckOut(dateTime.addDaysToDate(dateTime.tomorrow, searchConfig.defaultNights));
}
searchStore.setSearchParam('location', getDestinationObject.value);

// ### EVENT HOOKS
// The search form needs to listen for events from other components to avoid creating duplicate search form components on the application.
nuxtApp.hooks.hook('search-form:open', (tab) => {
  switch (tab) {
    case 'dates':
      searchData.handleDatesFieldClicked();
      break;
    case 'guests':
      searchData.handleGuestsFieldClicked();
      break;
    default:
      searchData.handleDestinationFieldClicked();
  }
});
</script>

<template>
  <div class="search-form-wrapper">
    <WdsSearchFormStacked
      class="search-form-stacked-container"
      :class="{ 'small-screen': hideOnSmallScreen }"
      v-bind="searchFormStackedParams"
      @destination-selected="searchData.handleDestinationFieldClicked"
      @destination-input="searchData.handleDestinationSearch"
      @destination-item-selected="searchData.handleDestinationItemSelected"
      @recent-destination-selected="searchData?.handleRecentDestinationItemSelected"
      @current-location="searchData.handleCurrentLocation"
      @dates-selected="searchData.handleDatesFieldClicked"
      @guests-selected="searchData.handleGuestsFieldClicked"
      @guests-change="searchData.handleGuestsNumberChanged"
      @guests-group-type-change="searchData.handleGuestsGroupTypeChanged"
      @guests-group-ages-change="searchData.handleGuestsGroupAgesChanged"
      @search-form-submit="searchData.handleSubmit"
    />
    <WdsSearchFormInline
      class="search-form-inline-container"
      v-bind="searchFormInlineParams"
      @destination-selected="searchData.handleDestinationFieldClicked"
      @destination-input="searchData.handleDestinationSearch"
      @destination-item-selected="searchData.handleDestinationItemSelected"
      @recent-destination-selected="searchData?.handleRecentDestinationItemSelected"
      @current-location="searchData.handleCurrentLocation"
      @dates-selected="searchData.handleDatesFieldClicked"
      @dates-changed="searchData.handleDatesSelected"
      @guests-selected="searchData.handleGuestsFieldClicked"
      @guests-change="searchData.handleGuestsNumberChanged"
      @guests-group-type-change="searchData.handleGuestsGroupTypeChanged"
      @guests-group-ages-change="searchData.handleGuestsGroupAgesChanged"
      @search-form-submit="searchData.handleSubmit"
    />
    <WdsSearchFormWizard
      class="search-form-wizard-container"
      v-bind="searchFormWizardParams"
      @destination-selected="searchData.handleDestinationItemSelected"
      @destination-input="searchData.handleDestinationSearch"
      @destination-item-selected="searchData.handleDestinationItemSelected"
      @recent-destination-selected="searchData?.handleRecentDestinationItemSelected"
      @current-location="searchData.handleCurrentLocation"
      @guests-change="searchData.handleGuestsNumberChanged"
      @guests-group-type-change="searchData.handleGuestsGroupTypeChanged"
      @guests-group-ages-change="searchData.handleGuestsGroupAgesChanged"
      @search-form-submit="searchData.handleSubmit"
      @search-form-close="searchData.handleWizardClose()"
    />
  </div>
</template>

<style scoped lang="scss">
.search-form-wrapper {
  width: 100%;

  .search-form-inline-container {
    display: none;
    margin: 0 auto;
  }

  .small-screen {
    display: none;
  }

  @include tablet-large {
    .search-form-stacked-container,
    .search-form-wizard-container {
      display: none;
    }

    .search-form-inline-container {
      display: block;
      max-width: wds-rem(1024px);
    }
  }
}
</style>
