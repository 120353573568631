<script setup>
import { useUrls } from '@/composables/useUrls';
import { WdsAccordion, WdsAccordionItem, WdsListItem } from '@wds/components';

const { useUrl } = useUrls();

const testingStore = useTestingStore();
const showAppDownloadOption = testingStore.hasEnabledFeature('web_app_download_banner');
</script>

<template>
  <div>
    <WdsAccordion>
      <WdsAccordionItem data-testid="accommodation-accordion">
        <template #header>
          {{ $t('t_ACCOMMODATION') }}
        </template>

        <template #content>
          <div class="h-menu-link-list">
            <ul>
              <WdsListItem
                :label="$t('t_HOSTELS')"
                :title="$t('t_HOSTELS')"
                :href="useUrl.getWorldPageUrl({ propertyTypeSlug: $t('t_SLUGPROPERTYTYPEHOSTELS') })"
              />
              <WdsListItem
                :label="$t('t_HOTELS')"
                :title="$t('t_HOTELS')"
                :href="useUrl.getWorldPageUrl({ propertyTypeSlug: $t('t_SLUGPROPERTYTYPEHOTELS') })"
              />
              <WdsListItem
                :label="$t('t_GUESTHOUSE')"
                :title="$t('t_GUESTHOUSE')"
                :href="useUrl.getWorldPageUrl({ propertyTypeSlug: $t('t_SLUGPROPERTYTYPEBNB') })"
              />
            </ul>
          </div>
        </template>
      </WdsAccordionItem>

      <WdsAccordionItem data-testid="explore-hostelworld-accordion">
        <template #header>
          {{ $t('t_EXPLOREHOSTELWORLD') }}
        </template>

        <template #content>
          <div class="h-menu-link-list">
            <ul>
              <WdsListItem
                :label="$t('t_BLOG')"
                :title="$t('t_BLOG')"
                :href="$t('t_URLBLOG')"
                rel="noopener noreferrer"
                target="_blank"
              />
              <WdsListItem
                :label="$t('t_BOOKINGGUARANTEE')"
                :title="$t('t_BOOKINGGUARANTEE')"
                :href="useUrl.getGenericPageUrl('guarantee')"
                rel="noopener noreferrer"
                target="_blank"
              />
              <WdsListItem
                :label="$t('t_HOSCARAWARDS')"
                :title="$t('t_HOSCARAWARDS')"
                :href="$t('t_HOSCARAWARDSURL')"
                rel="noopener noreferrer"
                target="_blank"
              />
            </ul>
          </div>
        </template>
      </WdsAccordionItem>

      <WdsAccordionItem data-testid="work-with-us-accordion">
        <template #header>
          {{ $t('t_WORKWITHUS') }}
        </template>

        <template #content>
          <div class="h-menu-link-list">
            <ul>
              <WdsListItem
                :label="$t('t_HOSTELSIGNUP')"
                :title="$t('t_HOSTELSIGNUP')"
                :href="$t('t_HWSIGNUPURL')"
                rel="noopener noreferrer"
                target="_blank"
              />
              <WdsListItem
                :label="$t('t_AFFILIATEPROGRAM')"
                :title="$t('t_AFFILIATEPROGRAM')"
                :href="$t('t_AFFILIATEPROGRAMURL')"
                rel="noopener noreferrer"
                target="_blank"
              />
              <WdsListItem
                :label="$t('t_INBOXLOGIN')"
                :title="$t('t_INBOXLOGIN')"
                :href="$t('t_INBOXLOGINURL')"
                rel="noopener noreferrer"
                target="_blank"
              />
            </ul>
          </div>
        </template>
      </WdsAccordionItem>
    </WdsAccordion>

    <WdsListItem
      v-if="showAppDownloadOption"
      href="https://hostelworld.onelink.me/IdiK/l5wn8um8"
      :label="$t('t_DOWNLOADTHEAPP')"
      :title="$t('t_DOWNLOADTHEAPP')"
      icon="smartphone"
      class="h-help-link"
      rel="noopener noreferrer"
      target="_blank"
      data-testid="app-download-link"
    />

    <WdsListItem
      :label="$t('t_HELP')"
      :href="$t('t_CONTACTUSURL')"
      :title="$t('t_HELP')"
      icon="question"
      class="h-help-link"
      rel="noopener noreferrer"
      target="_blank"
      data-testid="help-link"
    />
  </div>
</template>

<style lang="scss" scoped>
.h-menu-link-list {
  width: 100%;
}

.h-help-link {
  :deep(.item-content) {
    @include body-1-bld;
  }
}
</style>
