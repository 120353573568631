import { useCurrencyStore } from '@/stores/currency';

export function useApiCountries() {
  const { $api } = useNuxtApp();
  const { locale } = useI18n();
  const currencyStore = useCurrencyStore();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};

  const getCountryByName = async (propertyType, countryName) => {
    let result = ref(null);

    const callKey = `getCountryByName${locale?.value}${propertyType}${countryName}${userCurrency?.code}`;
    const endpoint = `country/${propertyType}/${countryName}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));
    return result?.value?.data || null;
  };

  const getCountryById = async (propertyType, countryId) => {
    let result = ref(null);

    const callKey = `getCountryById${locale?.value}${propertyType}${countryId}${userCurrency?.code}`;
    const endpoint = `country/${propertyType}/${countryId}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));
    return result?.value?.data || null;
  };

  const getAllCitiesByCountryName = async (propertyType, countryName) => {
    let result = ref(null);

    const callKey = `getAllCitiesByCountryName${locale?.value}${propertyType}${countryName}${userCurrency?.code}`;
    const endpoint = `country/${propertyType}/${countryName}/active-cities/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));
    return result?.value?.data || null;
  };

  const getAllRegionsByCountryId = async (propertyType, countryId) => {
    let result = ref(null);

    const callKey = `getAllRegionsByCountryId${locale?.value}${propertyType}${countryId}${userCurrency?.code}`;
    const endpoint = `country/${propertyType}/${countryId}/regions/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));
    return result?.value?.data || null;
  };

  const getAllAreasByCountryId = async (propertyType, countryId) => {
    let result = ref(null);

    const callKey = `getAllAreasByCountryId${locale?.value}${propertyType}${countryId}${userCurrency?.code}`;
    const endpoint = `country/${propertyType}/${countryId}/areas/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));
    return result?.value?.data || null;
  };

  const getAverageCityPricesByCountryName = async (propertyType, countryName) => {
    let result = ref(null);
    const callKey = `getAllCountries${locale?.value}${propertyType}${countryName}${userCurrency?.code}`;
    const endpoint = `country/${propertyType}/${countryName}/prices/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data?.cities || null;
  };

  return {
    getCountryById,
    getCountryByName,
    getAllCitiesByCountryName,
    getAllRegionsByCountryId,
    getAllAreasByCountryId,
    getAverageCityPricesByCountryName,
  };
}
