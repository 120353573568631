import { useCurrencyStore } from '@/stores/currency';

export function useApiAreas() {
  const { $api } = useNuxtApp();
  const { locale } = useI18n();
  const currencyStore = useCurrencyStore();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};

  const getAreaByName = async (propertyType, countryName, areaName) => {
    let result = ref(null);

    const callKey = `getAreaByName${locale?.value}area${propertyType}${countryName}${areaName}${userCurrency?.code}`;
    const endpoint = `area/${propertyType}/${countryName}/${areaName}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));
    return result?.value?.data || null;
  };

  const getAreaById = async (propertyType, areaId) => {
    let result = ref(null);

    const callKey = `getAreaById${locale?.value}area${propertyType}${areaId}${userCurrency?.code}`;
    const endpoint = `area/${propertyType}/${areaId}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));
    return result?.value?.data || null;
  };

  return {
    getAreaByName,
    getAreaById,
  };
}
